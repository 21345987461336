import React from 'react';
import logo from './assets/logo.png';
import or from './assets/or.png';
import products from './assets/product-image.png';
import reset from './assets/reset-search.png';
import './App.scss';
import Facet from './Facet';
import {FacetConfig, FacetID, FacetModel, FacetOption, MapFacetSelections, Product} from "./model";
import {fetchProducts} from "./products-api";
import {evaluateProduct, FACET_CONFIG} from "./facet-config";
import ProductTable, {TableColumn} from "./ProductTable";
import {keyBy, mapValues} from "lodash";


export default function App(): JSX.Element {
    const [startReset, setStartReset] = React.useState<number>(0);
    const [facetModels, setFacetModels] = React.useState<FacetModel[]>( [] );
    const [allProducts, setAllProducts] = React.useState<Product[] | undefined>( undefined );
    const [visibleProducts, setVisibleProducts] = React.useState<Product[] | undefined>(undefined);
    const [additionalTableColumn, setAdditionalTableColumn] = React.useState<TableColumn | undefined>(undefined);
    const [systemErrorMessage, setSystemErrorMessage ] = React.useState<string | undefined>(undefined);

    // attempt to pull Products list from the server
    React.useEffect(() => {
        let isSubscribed = true;
        // initialize the Facets empty
        setFacetModels( FACET_CONFIG.map((facetConfig: FacetConfig) => ({
            id: facetConfig.id,
            label: facetConfig.label,
            expanded: false,
            options: [],
            selectedOption: undefined
        })));
        setAllProducts(undefined);
        setVisibleProducts(undefined);
        setAdditionalTableColumn(undefined);
        setSystemErrorMessage(undefined);
        // initialize fetching of the product
        fetchProducts().then((products: Product[]) => {
            if ( isSubscribed ) {
                setFacetModels(FACET_CONFIG.map((facetConfig: FacetConfig, facetIndex: number) => ({
                    id: facetConfig.id,
                    label: facetConfig.label,
                    // Keep the first Facet collapsed on first initial fetch (when the page gets loaded);
                    // Expand the first Facet on all subsequent reloads (initiated from 'Reset' button);
                    expanded: (startReset !== 0 && facetIndex === 0),
                    options: facetConfig.generateOptions(products),
                    selectedOption: undefined
                })));
                setAllProducts(products);
            }
        }).catch((error) => {
            console.error(`Attempt to fetch Products failed: '${error}'`);
            if (isSubscribed) {
              setSystemErrorMessage('System cannot show Products due to the server error. Please try again later.');
            }
        });
        // clean up before running for next 'reset data' request
        return () => {
            // cancel any future 'setStores'
            isSubscribed = false;
        }
    }, [startReset]);


    const onFacetExpand = (facetId: FacetID, expand: boolean) => {
        const updatedFacets: FacetModel[] = facetModels.map((originalFacet: FacetModel) => ({
                ...originalFacet,
                expanded: (originalFacet.id === facetId) ? expand : false
            })
        );
        setFacetModels( updatedFacets);
    }

    const onFacetSelected = (facetId: FacetID, selectedOption: FacetOption | undefined ) => {
        // 1. Update the 'selected' model of the selected facet
        const facetChanged: FacetModel | undefined = facetModels.find((facet: FacetModel) => facet.id === facetId );
        if ( facetChanged ) {
            facetChanged.selectedOption = selectedOption;
            facetChanged.expanded = false;
        }

        // 2. Filter 'all products' and set 'visible products'
        // 2a. Build 'selected option value by facet id' map
        const facetSelections: MapFacetSelections = mapValues(
            keyBy( facetModels, 'id'),
            (facetModel: FacetModel) => facetModel.selectedOption?.value
        ) as MapFacetSelections;
        // 2b. filter the 'visible products' out of 'all products'
        const visibleProducts: Product[] = (allProducts ?? []).filter(
            (product: Product) => evaluateProduct(product, facetSelections)
        );

        // 3. Restrict Facet options according to the new options set. Sync up the 'selected' option on each Facet
        // with new options set.
        facetModels.forEach(( facetModel: FacetModel) => {
            // 4a. Restrict options for each Facet in accord with new resultset
            const facetConfig: FacetConfig | undefined = FACET_CONFIG.find(
                (facetConfig: FacetConfig) => facetConfig.id === facetModel.id
            );
            if (!facetConfig) {
                return;
            }
            // Calculate the new option set
            const newOptions: FacetOption[] = facetConfig.generateOptions( visibleProducts );
            // 4b. Synchronize the currently selected option. It is possible that 'currently selected option' may not
            // be on the list of 'new facet options' =>
            let currentSelection: FacetOption | undefined;
            const currentSelectionValue: string | undefined = facetModel.selectedOption?.value;
            if ( currentSelectionValue ) {
                currentSelection = newOptions.find((option: FacetOption) => option.value === currentSelectionValue);
            }
            facetModel.options = newOptions;
            facetModel.selectedOption = currentSelection;
        });

        // 4. Update the state of Facets and Table
        setVisibleProducts(visibleProducts);
        setFacetModels( [...facetModels]);

        // 5. Special processing for 'Wholesaler Number' facet: add additional column to the table if any option
        // of 'Wholesaler Number' facet is selected
        if ( facetId === FacetID.wholesalerNumber ) {
            setAdditionalTableColumn(selectedOption ? {
                    field: selectedOption.value as keyof Product,
                    label: selectedOption.label
                } : undefined
            );
        }
    }

    const getStarted = () => {
        if ( facetModels && facetModels.length > 0 && facetModels[0]?.options?.length > 0 ) {
            // Expand the first Facet and collapse the others
            facetModels.forEach((facetModel: FacetModel, index: number) => {
                facetModel.expanded = (index === 0);
            })
            setFacetModels([...facetModels]);
        }
    }

    return (
        <div className="app">
            <div className="top-nav">
                <div className="left-side">
                    <a className="logo-link"
                       target="_blank"
                       rel="noreferrer"
                       href="http://products.fresenius-kabi.us/">
                        <img src={logo} className="logo-image" alt="Fresenius Kabi USA"></img>
                    </a>
                    <div className="top-header">Local &amp; Regional Anesthesia Finder</div>
                </div>

                <div className="right-side">
                    <span role="link" className="nav-icon icon-reset"
                          title="Reset Filter"
                          aria-label="Reset Filter Button"
                          onClick={() => setStartReset(startReset+1)}>
                    </span>
                    <a className="nav-icon icon-email"
                       title="Contact Us"
                       aria-label="Contact Us Button"
                       target="_blank"
                       rel="noreferrer"
                       href="https://www.fresenius-kabi.com/us/contact">
                    </a>
                    <a className="nav-icon icon-info"
                       title="Pharmaceutical Product Updates"
                       aria-label="Product Information Button"
                       target="_blank"
                       rel="noreferrer"
                       href="https://www.fresenius-kabi.com/us/pharmaceutical-product-updates">
                    </a>
                    { visibleProducts !== undefined &&
                        <span className="nav-icon icon-print"
                              title="Print Page"
                              aria-label="Print Page Button"
                              onClick={window.print}></span>
                    }
                </div>
            </div>

            <div className="content-container">
                <div className='reset-container' role="button" title="Clear Search">
                    { visibleProducts !== undefined &&
                        <img src={reset} alt="Clear Search" onClick={() => setStartReset(startReset+1)}></img>
                    }
                </div>

                <div className="split-container">
                    <div className="left-panel">
                        { facetModels.map((facetModel: FacetModel) =>
                            <Facet key={facetModel.id}
                                   {...facetModel}
                                   onExpand={onFacetExpand}
                                   onSelect={onFacetSelected}
                            />)
                        }
                    </div>

                    <div className="right-panel">
                        { visibleProducts !== undefined &&
                            <ProductTable records={visibleProducts}
                                          additionalColumn={additionalTableColumn}/>
                        }

                        { visibleProducts === undefined &&
                            <div className='initial-screen'>
                                {systemErrorMessage &&
                                    <div className='error-message'>{systemErrorMessage}</div>
                                }

                                <div className="caption">
                                    A leading manufacturer of local and regional anesthesia products, Fresenius Kabi is
                                    dedicated to providing continuous service to healthcare providers and their patients.
                                    Explore our Local and Regional Anesthesia Finder to learn more about Fresenius Kabi's
                                    comprehensive anesthesia product portfolio, and identify the ordering information
                                    associated with the product and presentation best suited for your needs.
                                </div>

                                <div className="get-started">
                                    <div className="large-button" role="button"
                                    onClick={getStarted}>CLICK HERE TO GET STARTED</div>
                                    <img src={or} className="or-image" alt="OR"/>
                                    <div className="select-label">Select a Product from the menu</div>
                                </div>

                                <img src={products} className="products-image" alt="Products"></img>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="footer">
                <div className="footer-copy">
                    <div>Nesacaine is a trademark of Fresenius Kabi USA, LLC.</div>
                    <div>Polocaine is a trademark of Fresenius Kabi USA, LLC.</div>
                    <div>Naropin is a trademark of Fresenius Kabi USA, LLC.</div>
                    <div>Sensorcaine is a trademark of Fresenius Kabi USA, LLC.</div>
                    <div>Xylocaine is a trademark of Fresenius Kabi USA, LLC.</div>
                    <div>All rights reserved. Fresenius Kabi USA, LLC.</div>
                </div>

                <div className="footer-nav">
                    <a target="_blank"
                       rel="noreferrer"
                       href="http://www.fresenius-kabi.us/">FRESENIUS KABI USA</a>|
                    <a target="_blank"
                       rel="noreferrer"
                       href="http://www.fresenius-kabi.com/">FRESENIUS KABI AG</a>|
                    <a target="_blank"
                       rel="noreferrer"
                       href="https://www.fresenius-kabi.com/us/terms-of-use">TERMS OF USE</a>|
                    <a target="_blank"
                       rel="noreferrer"
                       href="https://www.fresenius-kabi.com/us/privacy-notice">PRIVACY NOTICE</a>|
                    <a target="_blank"
                       rel="noreferrer"
                       href="https://www.fresenius-kabi.com/us/data-privacy-contact">DATA PRIVACY CONTACT</a>|
                    <a target="_blank"
                       rel="noreferrer"
                       href="https://www.fresenius-kabi.com/us/cookie-notice">COOKIE NOTICE</a>|
                    <a target="_blank"
                       rel="noreferrer"
                       href="https://www.fresenius-kabi.com/us/consumer-health-data-privacy-notice-supplement">CONSUMER HEALTH DATA PRIVACY NOTICE SUPPLEMENT</a>|
                    <a target="_blank"
                       rel="noreferrer"
                       href="https://www.fresenius-kabi.com/us/data-privacy-contact">LIMIT THE USE OF MY SENSITIVE PERSONAL INFORMATION</a>
                </div>
            </div>
        </div>
    );
}
